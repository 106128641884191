import * as React from "react"
import * as styles from "./css/ApplicationProcess.module.styl"
import Layout from "../components/layout"
import { widthInfo } from "../util/index.js"
import Seo from "../components/seo"
import { graphql, useStaticQuery } from "gatsby"
import Img from "gatsby-image"
let width = widthInfo()

const ApplicationProcess = () => {
  const PictureData = useStaticQuery(graphql`
    query ApplicationProcessQuery {
      bannerPicture: file(
        relativePath: { eq: "ApplicationProcess/banner.png" }
      ) {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid
          }
        }
      }
      ApplicationProcess21: file(
        relativePath: { eq: "ApplicationProcess/ApplicationProcess21.png" }
      ) {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid
          }
        }
      }
      ApplicationProcess1: file(
        relativePath: { eq: "ApplicationProcess/ApplicationProcess1.png" }
      ) {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid
          }
        }
      }
      ApplicationProcess22: file(
        relativePath: { eq: "ApplicationProcess/ApplicationProcess22.png" }
      ) {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid
          }
        }
      }
      ApplicationProcess23: file(
        relativePath: { eq: "ApplicationProcess/ApplicationProcess23.png" }
      ) {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid
          }
        }
      }
      ApplicationProcess241: file(
        relativePath: { eq: "ApplicationProcess/ApplicationProcess241.png" }
      ) {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid
          }
        }
      }
      ApplicationProcess242: file(
        relativePath: { eq: "ApplicationProcess/ApplicationProcess242.png" }
      ) {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid
          }
        }
      }
      ApplicationProcess25: file(
        relativePath: { eq: "ApplicationProcess/ApplicationProcess25.png" }
      ) {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid
          }
        }
      }
      ApplicationProcess3: file(
        relativePath: { eq: "ApplicationProcess/ApplicationProcess3.png" }
      ) {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid
          }
        }
      }
      ApplicationProcess7: file(
        relativePath: { eq: "ApplicationProcess/ApplicationProcess7.png" }
      ) {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid
          }
        }
      }
      wainPicture: file(relativePath: { eq: "paybyEA/wain.png" }) {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `)
  const {
    bannerPicture,
    wainPicture,
    ApplicationProcess1,
    ApplicationProcess21,
    ApplicationProcess22,
    ApplicationProcess23,
    ApplicationProcess241,
    ApplicationProcess242,
    ApplicationProcess25,
    ApplicationProcess3,
    ApplicationProcess7,
  } = PictureData
  let gotop = () => {
    document.documentElement.scrollTop = 0
  }
  let scrollTop = 0
  return (
    <Layout>
      <Seo title="BarwaqtApplicationProcess"></Seo>
      {width > 1024 ? (
        <header className={styles.paybanner}>
          <div className={styles.paybannercenter}>
            <div className={styles.paybannerInfo}>
              <div className={styles.paybannerTitle}>
                Barwaqt
                <br />
                Application Process
              </div>

              <Img
                className={styles.paybannerLogo}
                fluid={bannerPicture.childImageSharp.fluid}
                alt="A corgi smiling happily"
              />
            </div>
          </div>
        </header>
      ) : (
        <div className={styles.H5banner}></div>
      )}

      <div className={styles.content}>
        {/* 中间内容 */}

        <div className={`${styles.contentTitle}`}>
          <span className={styles.contentTitleIcon}>
            {" "}
            {width > 1024 ? <span>STEP </span> : null}01
          </span>
          <span className={styles.contentTitleText}>
            Tap the “SEE MORE” button after selecting the loan amount and
            duration.
          </span>
        </div>
        <Img
          className={styles.pict481}
          fluid={ApplicationProcess1.childImageSharp.fluid}
          alt="A corgi smiling happily"
        />
        <div className={`${styles.contentTitle}`}>
          <span className={styles.contentTitleIcon}>
            {" "}
            {width > 1024 ? <span>STEP </span> : null}02
          </span>
          <span className={styles.contentTitleText}>
            Fill in the required information to proceed with your loan
            application.
          </span>
        </div>

        <div className={styles.marginTop24}></div>

        <div className={styles.foursamllPart}>
          <div className={styles.wain}>
            <Img
              className={styles.wainIcon}
              fluid={wainPicture.childImageSharp.fluid}
              alt="A corgi smiling happily"
            />
            <div className={styles.wainText}>
              Please fill in the valid information, it will only be used for
              your credit evaluation. We will ensure the security of your
              information.
            </div>
          </div>
          <div className={styles.marginTop24}></div>
          <div className={`${styles.secondTitle}`}>
            <span className={styles.secondTitleIcon}>01</span>
            <span className={styles.secondTitleText}>
              Fill in the basic and professional information.{" "}
            </span>
          </div>
          <Img
            className={styles.pict312}
            fluid={ApplicationProcess21.childImageSharp.fluid}
            alt="A corgi smiling happily"
          />
          <div className={`${styles.secondTitle}`}>
            <span className={styles.secondTitleIcon}>02</span>
            <span className={styles.secondTitleText}>
              Fill in the contact information.{" "}
            </span>
          </div>
          <Img
            className={styles.pict312}
            fluid={ApplicationProcess22.childImageSharp.fluid}
            alt="A corgi smiling happily"
          />
          <div className={`${styles.secondTitle}`}>
            <span className={styles.secondTitleIcon}>03</span>
            <span className={styles.secondTitleText}>
              Upload the front picture of your CNIC and complete the CNIC
              identity verification.
            </span>{" "}
          </div>
          <div className={`${styles.wain} ${styles.fourDoublePart} `}>
            <Img
              className={styles.wainIcon}
              fluid={wainPicture.childImageSharp.fluid}
              alt="A corgi smiling happily"
            />
            <div className={styles.wainText}>
              Please upload your own CNIC pictures, otherwise, it will affect
              your loan application.
            </div>
          </div>

          <Img
            className={styles.pict312}
            fluid={ApplicationProcess23.childImageSharp.fluid}
            alt="A corgi smiling happily"
          />
          <div className={`${styles.secondTitle}`}>
            <span className={styles.secondTitleIcon}>04</span>
            <span className={styles.secondTitleText}>
              Choose your payment method wisely. You can choose Mobile Wallet or
              Bank Account to receive the loan amount. But ensure that you use
              your own Mobile Wallet/Bank Account.
            </span>
          </div>
          <Img
            className={styles.pict312}
            fluid={ApplicationProcess241.childImageSharp.fluid}
            alt="A corgi smiling happily"
          />
          <Img
            className={styles.pict312}
            fluid={ApplicationProcess242.childImageSharp.fluid}
            alt="A corgi smiling happily"
          />
          <div className={`${styles.secondTitle}`}>
            <span className={styles.secondTitleIcon}>05</span>
            <span className={styles.secondTitleText}>
              Take a selfie for your Verification.
            </span>
          </div>
          <div className={styles.tips}>
            Ensure that you capture your own photo. To capture a clear picture,
            make sure there is a good light. Maintain a proper distance with the
            camera, or it may affect your loan application.
          </div>
          <Img
            className={styles.pict312}
            fluid={ApplicationProcess25.childImageSharp.fluid}
            alt="A corgi smiling happily"
          />
        </div>
        <div className={`${styles.contentTitle}`}>
          <span className={styles.contentTitleIcon}>
            {" "}
            {width > 1024 ? <span>STEP </span> : null}03
          </span>
          <span className={styles.contentTitleText}>
            The estimation of your loan will start. It will take around 20-30
            seconds.
          </span>
        </div>
        <Img
          className={styles.pict312}
          fluid={ApplicationProcess3.childImageSharp.fluid}
          alt="A corgi smiling happily"
        />
        <div className={`${styles.contentTitle}`}>
          <span className={styles.contentTitleIcon}>
            {" "}
            {width > 1024 ? <span>STEP </span> : null}04
          </span>
          <span className={styles.contentTitleText}>
            Once the loan estimation is completed, tap the “CONFIRM” button to
            confirm the loan. Please confirm within 72 hours, otherwise, you
            will not be able to get the loan.
          </span>
        </div>
        <div className={`${styles.contentTitle} ${styles.marginTop123}`}>
          <span className={styles.contentTitleIcon}>
            {" "}
            {width > 1024 ? <span>STEP </span> : null}05
          </span>
          <span className={styles.contentTitleText}>
            Your approved loan amount, duration, and repayment date will display
            on the App based on your evaluation result.
          </span>
        </div>
        <div className={`${styles.contentTitle} ${styles.marginTop123}`}>
          <span className={styles.contentTitleIcon}>
            {" "}
            {width > 1024 ? <span>STEP </span> : null}06
          </span>
          <span className={styles.contentTitleText}>
            Our staff will review your loan application and may contact you.
            Please make sure your phone is reachable.
          </span>
        </div>
        <div className={`${styles.contentTitle} ${styles.marginTop123}`}>
          <span className={styles.contentTitleIcon}>
            {" "}
            {width > 1024 ? <span>STEP </span> : null}07
          </span>
          <span className={styles.contentTitleText}>
            When the review pass successfully, the loan will be disbursed to
            your Mobile wallet/Bank account. After the successful disbursement
            of the loan. The amount to be paid back will be displayed in the
            Order Information of your account.
          </span>
        </div>
        <Img
          className={styles.pict312}
          fluid={ApplicationProcess7.childImageSharp.fluid}
          alt="A corgi smiling happily"
        />
      </div>
    </Layout>
  )
}

export default ApplicationProcess
